@import './../../../assets/scss/variables.scss';

.logo {
  padding: 12px 0;
  margin: 0 auto;
  position: relative;
  z-index: 4;
  display: block;
  font-family: 'Comfortaa';
  font-size: 2rem;
  text-align: center;

  &::before {
    content: "";
    position: absolute;
    bottom: 0;
    right: 15px;
    width: calc(100% - 30px);
    height: 1px;
    background-color: hsla(0,0%,100%,.3);
  }

  .dots {
    margin-bottom: -1.6rem;
    margin-left: 2.75rem;
    span {
      margin: 0 .07rem;
      svg {
        max-width: 1.3rem;
        circle.one {
          fill: $dotGreen;
        }
        circle.two {
          fill: $dotYellow;
        }
        circle.three {
          fill: $dotBlue;
        }
  
      }
    }
  }
}