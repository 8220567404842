@import './../../../assets/scss/variables.scss';

.card {
  border-radius: 4px;
  box-shadow: 0 1px 2px rgba(0,0,0,.05), 0 0 0 1px rgba(63,63,68,.1);
  background-color: #fff;
  margin-bottom: 30px;

  .header {
    padding: 15px 15px 0;
    color: #333;
    font-weight: 300;
    font-size: 22px;
  }

  .title {
    margin: 0;
    color: #333;
    font-weight: 300;
  }

  .category {
    font-size: 14px;
    font-weight: 400;
    color: #444;
    margin-bottom: 0;
  }

  .content {
    padding: 15px;

    .table-responsive {
      padding: 15px;
    }
  }
}

.actions {
  text-align: right;
  i {
    margin: 0 1rem;
  }
  i.fa-edit {
    color: $warning;
  }
  i.fa-trash {
    color: $danger;
  }
}