ul.nav {
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
  margin-top: 20px;

  li {
    position: relative;
    display: inline-block;
    width: 100%;
    text-align: center;

    &:hover {
      a {
        background: hsla(0,0%,100%,.13);
        opacity: 1;
        color: #fff;
      }
    }

    a {
      position: relative;
      display: inline-flex;
      padding: .7rem;
      border-radius: 4px;
      color: #fff;
      opacity: .86;
      width: 90%;
      margin: 0 auto;

      &:hover {
        text-decoration: none;
      }
    }

    &.active {
      a {
        color: #fff;
        opacity: 1;
        background: hsla(0,0%,100%,.23);
      }
    }

    p {
      margin: 0;
      line-height: 30px;
      font-size: .8rem;
      font-weight: 600;
      text-transform: uppercase;
      white-space: nowrap;
      color: #fff;
      -webkit-transform: translateZ(0);
      transform: translateZ(0);
      height: auto;
      opacity: 1;
      margin-left: .6rem;
    }

    i {
      font-size: 2rem;
      margin-right: .7rem;
    }
  }
}