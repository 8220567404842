@import './../../../assets/scss/variables.scss';

.navbar {
  margin-bottom: 0;
  border: 0;
  font-size: 1rem;
  border-radius: 0;

  &.navbar-default {
    background-color: hsla(0,0%,100%,.96);
    border-bottom: 1px solid rgba(0,0,0,.1);
    width: 100%;
    height: 72px;
    text-align: right;
  }
  .navbar-header {
    width: 100%;

    .user {
      font-family: inherit;
    }

    .btn {
      margin: 0 1rem;
    }

    .user-button {
      margin: 0 2rem;
      cursor: pointer;
      vertical-align: middle;
      display: inline-block;
      color: $gray;
      i {
        font-size: 2rem;
        vertical-align: middle;
      }
      span {
        vertical-align: middle;
      }
    }
  }
}
