
.sidebar {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  width: 260px;
  display: block;
  z-index: 1;
  color: #fff;
  font-weight: 200;
  overflow-x: hidden;
  max-height: 100%;
  height: 100%;
  -webkit-transition-property: top,bottom,width;
  transition-property: top,bottom,width;
  -webkit-transition-duration: .2s,.2s,.35s;
  transition-duration: .2s,.2s,.35s;
  -webkit-transition-timing-function: linear,linear,ease;
  transition-timing-function: linear,linear,ease;
  -webkit-overflow-scrolling: touch;

  &::before {
    display: block;
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 2;
    opacity: .9;
    background: #000;

  }

  .sidebar-background {
    position: absolute;
    z-index: 1;
    height: 100%;
    width: 100%;
    display: block;
    top: 0;
    left: 0;
    background-size: cover;
    background-position: 50%;
    background-image: url(./../../../assets/images/background.jpg);
  }

  .sidebar-wrapper {
    overflow: auto;
    position: relative;
    z-index: 4;
    width: 260px;
    height: calc(100% - 95px);
    padding-bottom: 30px;
    box-shadow: inset -1px 0 0 0 #cfcfca;
  }
}