
.wrapper {
  position: relative;
  top: 0;
  height: 100vh;
  min-height: 100vh;
}

.main-panel {
  position: relative;
  float: right;
  width: calc(100% - 260px);
  min-height: 100%;
  .main-content {
    padding: 30px 15px;
    min-height: calc(100vh - 72px);
    background-color: #f5f5f5;
  }
}