.btn {
  display: inline-block;
  padding: 6px 12px;
  margin-bottom: 0;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.42857143;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-image: none;
  border: 1px solid #444;
  border-radius: 4px;
  border-width: 1px;
  background-color: initial;
  font-weight: 400;
  opacity: .8;
  padding: 8px 16px;
  color: #444;
  i {
    margin: 0 .5rem;
  }
}
.btn-wd {
  min-width: 140px;
}
.btn-default {
  color: #333;
  background-color: #fff;
  border-color: #ccc;
}