.btn-submit {
  color: #fff;
  background-color: #23ccef;
  opacity: 1;
  border: 1px solid #23ccef;
  transition: all 60ms ease-in;
  font-weight: 400;
  padding: 8px 16px;

  &:hover {
    background-color: #11bfe3;
    color: #fff;
  }
}