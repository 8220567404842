@import './../../../assets/scss/variables.scss';

.production-line-page {
  .ps-page {

    .filters {
      background: none;
    }
  }
  
  .perfomed-services-container {
    margin: 0 2rem;
  
    .ps-card {
      border: 1px solid #9a9a9a;
      margin: 1rem;
      padding: 2rem;
      text-align: center;
      border-radius: 1rem;
      height: 85%;
      position: relative;
      padding-bottom: 5rem;
  
      button {
        margin: 1rem 0;
        background: $danger;
        color: #ffffff;
        border: 1px solid #ffffff;
        font-weight: bold;
        border-radius: 0 0 1rem 1rem;
        width: 100%;
        position: absolute;
        bottom: -1rem;
        left: 0;
        padding: 1rem 0;
        border: 1px solid $danger;
  
        &.btn-is-done {
          background: $success;
          border: 1px solid $success;
        }

        &.btn-orange {
          background: $warning;
          border: 1px solid $warning;
        }
      }
    }
  }
  
  .title {
    width: 100%;
    text-align: center;
    padding: 1.5rem;
  }
  
  a.back {
    display: block;
    color: inherit;
    margin: 1.8rem 2rem;
  }

}
