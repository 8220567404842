@import './assets/fonts/roboto/roboto.css';
@import './assets/fonts/comfortaa/comfortaa.css';
@import './assets/fonts/pe-icon-7-stroke/css/pe-icon-7-stroke.css';
@import './assets/fonts/fontawesome/css/all.css';
@import './assets/scss/variables.scss';
@import './assets/scss/buttons.scss';


body {
  margin: 0;
  padding: 0;
  color: #333;
  font-family: 'Roboto';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

form {
  .form-group {
    margin: .5rem 1rem;
    label {
      display: block;
      font-size: .8rem;
      text-transform: uppercase;
    }
    .form-control {
      border: 1px solid #e3e3e3;
      border-radius: 4px;
      color: #565656;
      padding: 8px 12px;
      height: 40px;
      background-color: #fff;
      box-shadow: none;
      &:focus {
        background-color: #fff;
        box-shadow: none;
      }
      &input {
        font-size: .8rem;
      }
      &:disabled {
        background: #e9ecef;
      }

    }
  }
}

.filters {
  margin: 1rem 2rem;
  background-color: rgba(0, 0, 0, 0.02);
  border-radius: .5rem;
  padding: .5rem 1rem 1rem;

  h5 {
    margin: .5rem 0;
  }
  select {
    border: 1px solid #e3e3e3;
    border-radius: 4px;
    color: #565656;
    font-weight: 400;
    height: 36px;
    background-color: inherit;
    text-align: center;
    width: 100%;
  }
}

.react-datepicker-wrapper,
.react-datepicker__input-container {
  width: 100%;
}

.card {
  .header {
    a {
      position: absolute;
      right: 2rem;
      top: 2rem;
      
      &:hover {
        text-decoration: none;
      }
    }
  }
}

i.delete {
  cursor: pointer;
}

.pagination {
  display: flex;
  justify-content: center;
}

.pagination>li:first-child>a,
.pagination>li:first-child>span,
.pagination>li:last-child>a,
.pagination>li:last-child>span,
.pagination>li>a,
.pagination>li>span {
  border-radius: 50%;
  margin: 0 2px;
  color: #353535;
}

.pagination>li>a {
  position: relative;
  float: left;
  padding: 6px 12px;
  line-height: 1.42857143;
  text-decoration: none;
  background-color: #fff;
  border: 1px solid #ddd;
}

.pagination>li.active>a,
.pagination>li.active>a:focus,
.pagination>li.active>a:hover,
.pagination>li.active>span,
.pagination>li.active>span:focus,
.pagination>li.active>span:hover {
  background-color: #23ccef;
  border: 0;
  color: #fff;
  padding: 7px 13px;
  z-index: 3;
  cursor: default;
}