
.wrapper {
  position: relative;
  top: 0;

  &.wrapper-full-page {
    height: auto;
    min-height: 100vh;
  }
}

.full-page {
  &::after {
    display: block;
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 2;background: #282828;
    background: -webkit-gradient(linear,left top,left bottom,from(#282828),to(rgba(17,17,17,.4)));
    background: linear-gradient(180deg,#282828 0,rgba(17,17,17,.4));
    background-size: 150% 150%;
    z-index: 3;
    opacity: .9;
  }
  &::before {
    display: block;
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 2;
    opacity: .9;
    background: #000;
  }
  .full-page-background {
    position: absolute;
    z-index: 1;
    height: 100%;
    width: 100%;
    display: block;
    top: 0;
    left: 0;
    background-size: cover;
    background-position: 50%;
    background-image: url(./../../assets/images/background.jpg);
  }
}

.full-page.has-image:after, .full-page[data-image]:after {
  opacity: .1;
}

.login-page {
  .card {
    width: 400px;
    box-shadow: 0 25px 30px -13px rgba(40,40,40,.4);
    border-radius: 10px;
    padding-top: 30px;
    padding-bottom: 30px;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    background-color: #fff;
    margin: 0 auto;
    margin-bottom: 30px;

    .header {
      padding: 15px 15px 0;
      color: #333;
      font-weight: 300;
      font-size: 22px;

      .title {
        font-weight: 300;
        margin-bottom: 3rem;
      }
    }
  }

  button:disabled {
    opacity: .5;
    pointer-events: none;
    cursor: default;
  }

  .btn.btn-info {
    color: #fff;
    background-color: #138496;
    border-color: #117a8b;
    width: 200px;
    margin: 2rem auto 1rem;
    display: block;
  }
}

.forget-password-link,
.back-link,
a.back-link {
  width: 100%;
  text-align: center;
  font-size: .8rem;
  cursor: pointer;
  text-decoration: underline;
  display: block;
  color: inherit;
}

.card-has-error {
  padding: .5rem 2rem;
  text-align: center;
  background: #f0f0f0;
  margin: 1rem;
  border-radius: .5rem;
}

.login-page>.content {
  padding-top: 22vh;
}

.full-page>.content {
  min-height: calc(100vh - 70px);
  position: relative;
  z-index: 4;
}